.About {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-title {
    font-size: 130px;
    line-height: 95%;
    font-family: "FreeSans";
}
.about-text-img {
    display: flex;
    justify-content: space-between;
}

.ab-left,.ab-right {
    width: 50%;
}

.ab-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ab-passions {
display: flex;
justify-content: space-between;
}

.pas-left, .pas-right {
    width: 50%;
}

.pas-title {
    font-size: 30px;
    margin-bottom: 10px;
    font-weight: 500;
}

.pas-item {
    margin-bottom: 10px;
}

.ab-right {
    height: 1000px;
    display: none;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.ab-bread {
    font-weight: 500;
    font-size: 40px;
    line-height: 110%;
    padding-top: 40px;
}

.abo-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.abo-img-desk {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ab-b {
    padding-bottom: 200px;
}


.threerow {
    display: flex;
    gap:40px;
    height: auto;
}

.ab-exp {
    height: auto;
    flex-grow: 1;
}

.exp-num {
 font-size: 60px;
 font-weight: 500;
}

.e-border {
    border-bottom: 4px solid #ECECEC;
}

.exp-tags {
display: flex;
gap:10px;
padding: 10px 0px;
}

.exp-tag {
    background-color: grey;
    padding: 4px;
    border-radius: 8px;
    font-weight: 500;
}

.exp-des {
    background-color:#8CF1FF;
}

.exp-dev {
    background-color: #FFA8A8;
}

.exp-co {
    background-color: #48f1ab;
}

.exp-devdev {
    background-color: #D1FF4D;
}

.exp-int {
    background-color: #daa6ff;
}

.exp-title {
    display: block;
    padding: 10px 0px;
    font-size: 30px;
    font-weight: 600;
    width: 100%;
}

.exp-date {
    padding: 10px 0px;
}

.exp-place {
    padding: 10px 0px;
}

.exp-url {
    padding: 10px 0px;
    font-weight: 500;
}

.exp-bread {
    font-weight: 500;
    padding: 10px 0px;
}

.mobionly {
    display: block;
}

.abo-div-mob {
    margin-top: 40px;
    background-image: url(images/blurs/usacsmileinv-min-small.png);
}

.abo-div {
    width: 70%;
    height: 500px;
    background-image: url(images/blurs/usacsmileinv-min-small.png);
}

@media screen and (max-width:600px) {
    .about-title {
        padding-top: 20px;
        font-size: 40px;
    }
    .mobionly {
        display: block;
    }

    .ab-left {
        width: 100%;
    }

    .ab-right {
        display: none;
    }

    .ab-bread {
        font-size: 20px;
    }

    .abo-img {
        width: 100%;
        height: 360px;
    }

    .ab-passions {
        padding-top: 40px;
    }

    .pas-title {
        font-size: 20px;
    }

    .pas-item {
        font-weight: 500;
    }

    .threerow {
        padding-top: 40px;
        flex-direction: column;
    }
}

@media screen and (min-width:600px) {
    .about-title {
        padding-top: 40px;
        font-size: 50px;
    }

    .mobionly {
        display: none;
    }

    .ab-bread  {
        font-size: 20px;
    }

    .pas-title {
        font-size: 20px;
    }

    .pas-item {
        font-weight: 500;
    }

    .ab-right {
        display: flex;
        height: 800px;
    }

    .abo-img {
        height: 400px;
        width: auto;
    }

    .threerow {
        flex-wrap: wrap;
    }

}

@media screen and (min-width:900px) {
    .about-title {
        font-size: 80px;
    }

    .abo-div-mob {
        display: none;
    }
    .mobionly {
        display: none;
    }

    .ab-bread  {
        font-size: 30px;
    }

    .pas-title {
        font-size: 20px;
    }

    .ab-right {
        display: flex;
        height: 700px;
    }

    .abo-img {
        height: 500px;
    }

    .threerow {
        padding-top: 40px;
    flex-wrap: nowrap;
    }

    .ab-exp {
        width: 50%;
        flex-grow: 0;
    }

    .exp-tags {
        flex-wrap: wrap;
    }

    .small-indicator {
        font-size: 20px;
    }

}

@media screen and (min-width:1500px) {
    .about-title {
        padding-top: 0px;
        font-size: 120px;
    }

}