.casestudypage {
    display: flex;
    justify-content: center;
}

.case-oversight {
    font-size: 20px;
    color: #747E86;
    line-height: 110%;
    padding-bottom: 20px;
    padding-top: 20px;
    font-weight: 500;
    width: 100%;
}

.case-header {
    font-size: 40px;
    font-weight: 500;
    line-height: 110%;
}

.hed-hanger {
    width: 100%;
    object-fit: cover;
}

.header-img-osb {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.case-header-img {
    width: 100%;
    margin-bottom: 40px;
}

.case-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 160px;
}

.case-basics {
    font-size: 30px;
    color: #747E86;
    font-weight: 400;
    padding-bottom: 20px;
    line-height: 110%;
    width: 100%;
}

.casestudy-basics {
    width: 100%;
}

.casestudy-bread {
    font-size: 20px;
    padding-bottom: 40px;
    line-height: 140%;
}

.casestudy-section-title {
    font-size: 40px;
    line-height: 110%;
    padding-bottom: 40px;
    font-weight: 500;
    width: 100%;
}

.number-indicator {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 3px solid #C50636;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 20px;
}

.nb-dark {
    border: 3px solid #000;
}

.casestudy-undertitle {
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 20px;
    width: 100%;
}

.csunderlg {
    font-size: 30px;
}

.casestudy-cta {
    text-decoration: underline;
    font-size: 20px;
    font-weight: 500;
}

.massive-img-osb {
    object-fit: cover;
    max-height: 500px;
}

.heroborder {
    padding-bottom: 10px;
    border-bottom: 3px solid #D9D9D9;
    margin-bottom: 10px;
    gap: 10px;
}

.case-lateral {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.case-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.case-box-img-smaller {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
   
    img {
        object-fit: cover;
        height: 100%;
        max-width: 80%;
        padding: 20px;
    }
}


/* OneStepBeyond */

.osb-overviewtext {
    font-size: 20px;
    line-height: 140%;
    width: 100%;
    padding-bottom: 40px;
}

.hed-hanger {
    height: 100%;
}

.hangerdiv {
    flex-grow: 1;
    border-radius: 10px;
    background-image: url(images/blurs/hangers-small.png);
    overflow: hidden;
    width: 100%;
}

.osbdiv {
    border-radius: 10px;
    background-image: url(images/blurs/osbcase-small.png);
    width: 100%;
    overflow: hidden;
}

/*Laghmari */

.laghmari-body {
    min-height: 100vh;
}

.lghm-header-bild {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.lghm-box {
    height: 200px;
}

.lghm-lateral {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.titel-lateral {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 80px;
}

.titel-s {
    font-size: 20px;
    font-weight: 500;
}

.Laghmari-titel {
    font-size: 60px;
    font-weight: 700;
}

.titel-b {
    font-size: 60px;
    font-weight: 500;
}

.prelude {}


.mobile-lghm-prelude {
    font-size: 30px;
    font-weight: 500;
    padding-bottom: 20px;
}

.lghm-prelude {
    font-size: 20px;
}

.lghms-basics {
    font-size: 30px;
    padding-bottom: 10px;
}

.lghm-bread-text {
    font-size: 20px;
    padding-bottom: 80px;
}

.lghm-tag {
    width: fit-content;
    padding: 8px;
    font-size: 16px;
    line-height: 100%;
    border-radius: 8px;
    margin-bottom: 20px;
}

.lghm-content-box {
    width: 100%;
}

.lghm-UI-bild {
    width: 100%;
    padding-bottom: 20px;
}

.desk-cool-bild {
    display:none;
}

.cool-lghmbild {
    width: 100%;
}

.maincolor {
    width: 100%;
    height: 200px;
    background-color: #72876A;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.othercolor {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.darkblue {
    background-color: #000214;
}

.lightwhite {
    background-color: #F2FDFF;
}

.darkgreen {
    background-color: #4C5E56;
}

.lghmyellow {
    background-color: #F4D06F;
}

.warningorange {
    background-color: #FF8811;
}

.lghm-colors-padding {
    padding-top: 20px;
    padding-left: 20px;
    font-size: 40px;
    font-weight: 500;
}

.color-code-white {
    font-size: 20px;
    color: white;
    opacity: .75;
    padding-left: 20px;
    padding-bottom: 20px;
}

.color-code-dark {
    font-size: 20px;
    color: black;
    opacity: .75;
    padding-left: 20px;
    padding-bottom: 20px;
}

.colors-gap {
    gap: 0px;
}

.lghmpromoimg {
    width: 100%;
}

.lghmvanurl {
    background-image: url(images/blurs/lghmvan-small.png);
}

.lghmoffice {
    background-image: url(images/blurs/lghmoffice-small.png);
}

.promo-left {
    width: 100%;
}

.promo-right {
    width: 100%;
}


/* Krypto */

.havebeforebox {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #D9D9D9;
    margin-bottom: 10px;

    img {
        width: 70%;
        object-fit: cover;
        max-height: 100%;
    }
}

.havebeforebg {
    background-color: #D9D9D9;
}

.hbpadd {
    padding-bottom: 40px;
    max-width: 80%;
}

.krypto-lateral {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.krypto-header {
    width: 100%;
}

.outcome-box {
    width: 80px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;

    img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}

.case-header-krypto-div {
    overflow: hidden;
    width: 100%;
    height: 500px;
    border-radius: 10px;
    background-position: center;
    background-size: cover;
    background-image: url(images/blurs/kryptos4-small.png);
}

.case-header-krypto {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.scrolltext-kryptocase {
    text-align: right;
    width: 100%;
}

.lghmgap {
    gap: 0px;
}

.desk-lghm-landing {
    display: none;
}

.biglogo {
    display: none;

}

.hbboxgap {
    gap:0px;
}

@media screen and (max-width:375px) {
    .Laghmari-titel {
        font-size: 40px;
    }
}


@media screen and (min-width:900px) {
    .hbboxgap {
        gap:20px;
    }

    .case-oversight {
        padding-bottom: 0px;
    }

    .osb-overviewtext {
        font-size: 30px;
        line-height: 140%;
        width: 70%;
        padding-bottom: 40px;
    }

    .case-section {
        margin-bottom: 200px;
    }

    .hangerdiv {
        max-width: 60%;
    }

    .case-lateral {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 30px;
    }

    .case-oversight {
        padding-top: 60px;
    }

    .case-header {
        font-size: 50px;
    }

    .heroborder {
        padding-bottom: 10px;
        border-bottom: 3px solid #D9D9D9;
        margin-bottom: 10px;
        gap: 10px;
    }


    .case-header-img {
        width: 50%;
        height: auto;
        object-fit: cover;
    }


    .hed-hanger {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }

    .header-img-osb {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }

    .box-x-axis {
        align-items: center;
    }

    .case-basics {
        width: 40%;
        min-width: 140px;
    }

    .casestudy-bread-intro {
        font-size: 20px;
        line-height: 140%;
        width: 70%;
    }

    .goal {
        flex-basis: 33%;
    }


    .testareas {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .case-box {
        width: 50%;
        height: 100%;

        img {
            width: 100%;
        }
    }

    .case-box-img-smaller {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 70%;
        }
    }


    .test-findings-wid {
        width: 70%;
    }

    /*KRYPTOSKATT */
    .krypto-lateral {
        display: flex;
        flex-direction: row-reverse;
        gap: 40px;
        padding-top: 40px;
    }

    .krypto-header {
        font-size: 40px;
        line-height: 110%;
        padding-top: 0px;
    }


    .kryptobox {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .kryptooversight {
        padding-top: 0px;
    }

    .havebeforebox {
        height: 350px;
        margin-bottom: 20px;

        img {
            width: 70%;
            object-fit: cover;
            max-height: 100%;
        }
    }

    /* LAGHMARI */
    .lghm-box {
        width: 50%;
        height: 400px;
    }

    .lghm-box-lower {
        width: 50%;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .titel-lateral {
        color: #FFF;
        margin-bottom: 0px;
        padding: 0px 10px 10px;
    }

    .lghmgap {
        gap: 0px;
    }

    .mobile-lghm-box {
        display: none;
    }

    .Laghmari-titel {
        padding-right: 10px;
        font-size: 30px;
        font-weight: 700;
        color: white;
        text-align: right;
    }

    .titel-s {
        font-size: 20px;
    }

    .titel-b {
        font-size: 20px;
    }

    .mobile-lghm-prelude {
        padding-bottom: 10px;
        font-weight: 700;
    }

    .datesrow1 {
        width: 50%;
        display: flex;
        justify-content: space-between;
    }

    .first-step,
    .second-step {
        color: black;
        padding-top: 10px;
    }

    .third-step {
        color: white;
        padding-top: 10px;
        padding-left: 10px;
    }

    .biglogo {
        img {
            width: 100%;
        }
        padding-bottom: 40px;
        display: flex;
        justify-content: center;
    }

    .colors-lateral {
        display: flex;
        height: 200px;
    }

    .othercolor {
        height: 200px;
    }

    .othercolor-lastsection {
        width: 100%;
    }

    .warningorange, .lghmyellow {
        height: 100px;
    }

    .maincolor {
        height: 200px;
    }

    .desk-cool-bild {
        display: block;
    }

    .promo-right {
        width: 70%;
    }

    .promo-left {
        width: 30%;
    }
    
}

@media screen and (min-width:1200px) {

    .casestudy-section-title {
        padding-bottom: 60px;
    }

    .case-oversight {
        padding-top: 40px;
    }

    .kryptooversight {
        padding-top: 0px;
    }

    .case-header {
        font-weight: 500;
        font-size: 50px;
    }

    .casestudy-bread-intro {
        font-size: 30px;
    }

    .havebeforebox {
        height: 400px;
        margin-bottom: 20px;

        img {
            width: 70%;
            object-fit: cover;
            max-height: 100%;
        }
    }

    .hangerdiv {
        width: auto;
    }

    .osbdiv {
        width: 40%;
    }

    .case-lateral-lghm-lg {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-end;
        gap: 20px;
    }

    .hbboxgap {
        gap:20px;
    }

}

@media screen and (min-width:1500px) {
    .casestudy-section-title {
        padding-bottom: 80px;
    }

    .case-oversight {
        padding-top: 20px;
    }

    .case-header {
        max-width: 1200px;
        font-weight: 500;
        font-size: 60px;
    }

    .casestudy-bread-intro {
        font-size: 30px;
        font-weight: 500;
        line-height: 140%;
    }

    .hangerdiv,
    .osbdiv {
        height: 350px;
    }

    .casestudy-bread {
        font-size: 20px;
    }

    .havebeforebox {
        height: 500px;
        margin-bottom: 20px;

        img {
            width: 70%;
            object-fit: cover;
            max-height: 100%;
        }
    }

    .osb-overviewtext {
        font-size: 40px;
        margin-bottom: 80px;
    }

    .pbfixed {
        padding-bottom: 20px;
    }
    
    .hbboxgap {
        gap:20px;
    }
}