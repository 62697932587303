@keyframes moveup {
    from{
      opacity:0;
      transform: translate3d(0px, 300%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
    }
    to{
      opacity:1;
      transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    }
  }
  
  @-webkit-keyframes moveup {
    from{
      opacity:0;
      transform: translate3d(0px, 300%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
    }
    to{
      opacity:1;
      transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    }
  }
  
  @-moz-keyframes moveup {
    from{
      opacity:0;
      transform: translate3d(0px, 300%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
    }
    to{
      opacity:1;
      transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    }
  }

  @keyframes fadeIn{
    from{
        opacity:0;
      }
      to{
        opacity:1;
      }
  }

  @keyframes moveText {
    0%, 100% {
      transform: translateX(0%);
    }
    50% {
      transform: translateX(100%);
    }
    51% {
      transform: translateX(-100%);
    }
  }