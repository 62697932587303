// General //
$mobile-wid:calc(100% - 30px);
$pad-wid:calc(100% - 100px);
$medium-wid:calc(100% - 200px);
$lg-wid:calc(100% - 400px);

* {
    // border: solid 1px red;
}

@font-face {
font-family: 'Mona-sans';
src:
    url('Mona-Sans.woff2') format('woff2 supports variations'),
    url('Mona-Sans.woff2') format('woff2-variations');
font-weight: 200 300 400 500 600 700;
}

@font-face {
font-family: 'FreeSans';
src:
url('Free Sans Bold.woff2') format('woff2 supports variations'),
url('Free Sans Bold.woff2') format('woff2-variations');
    font-weight: 700;
}

@import "transitions";
@import "about";
@import "studies";

body {
height:auto;
}

a {
text-decoration: none;
color:black;
}

.Container {
display: flex;
justify-content: space-between;
align-items: center;
width:$mobile-wid;
height:100%;
}

.Container-column {
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: flex-start;
padding-top: 80px;
width:$mobile-wid;
}

    .button-effect, .button-effect-dark {
    position: relative;
    padding-bottom: 4px;
    }

    .button-effect:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: #000;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
    }

    .button-effect-dark:after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 3px;
        bottom: 0;
        left: 0;
        background-color: #FFF;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }

    .button-effect:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
    }

    .button-effect-dark:hover:after {
        transform: scaleX(1);
        transform-origin: bottom left;
        }

// General //

/* Header */

header {
font-weight: bold;
width: 100%;
height: auto;
min-height: 75px;
background-color:white;
display: flex;
justify-content: center;
align-items: center;
position: fixed;
top: 0;
transition: top 0.2s ease-in-out;
z-index: 2;
}

.nav-up {
    top: -75px;
}

.ptophead {
    margin-top: 10px;
}

.desklogo {
    display: none;
    width:100px;
}

.moblogo {
width:100px;
}

nav {
display: flex;
gap:30px;
}

.Navlink {
display: none;
}

.hamburger {
display: flex;
flex-direction: column;
gap:4px;
cursor: pointer;
z-index: 4;

}

.bun-top {
width: 25px;
height:4px;
background-color:black;
border-radius: 2px;
transition: all .2s ease-in-out;
}

.bun-mid {
width: 25px;
height:4px;
background-color:black;
border-radius: 2px;
transition: all .2s ease-in-out;
}

.bun-bot {
width: 25px;
height: 4px;
background-color: black;
border-radius: 2px;
transition: all .2s ease-in-out;
}

.side-menu {
top: -100vh;
display: none;
width: 100%;
height: 100%;
position: fixed;
background-color: #060107;
right:0;
transition: all 0.4s ease-in-out;
z-index: 3; /* Increase the z-index value */
}

.sidemenucontainer {
    width:$mobile-wid;
    display: flex;
    flex-direction: column;
    gap:20px;
    padding-top: 100px;
    padding-left: 20px;
}

.sidemenulinks {
    color:white;
    font-weight: 600;
    font-size: 40px;
    text-align: right;
}

.visible {
display: none;
top:0;
}

.whitebun {
    background-color: white;
}

/* Header */

/* Home page */

.Home {
padding-top: 100px;
display: flex;
flex-direction: column;
align-items: center;
// background-color: orangered;
}

.Hero-text {
    font-family: "FreeSans";
    font-weight: 700;
    font-size: 50px;
    line-height: 0.9;
    letter-spacing: -3px;
}

.line-wrapper {
    overflow: hidden;
    padding-bottom: 10px;
}

.starone {
    display: flex;
    gap:15px;
    align-items: center;
}

.isacstar {
    width:40px;
    height: auto;
}

.herobread {
    font-size: 20px;
    width: 100%;
    max-width: 600px;
    font-weight: 500;
}

.herocta {
    font-size: 20px;
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.imgdefi {
    width:300px;
    height:320px;
    object-fit: cover;
    transition: all .3s ease-out;
}

.imgdefi:hover {
    transform: scale(95%);
    filter: blur(3px);
}

.osbgif, .kryptogif, .lghmgif {
    opacity: 0;
    width:300px;
    height:auto;
    object-fit: cover;
    position: absolute;
    z-index: 1;
    pointer-events: none;
    transform:scale(95%);
    transition: all .4s ease-out;
    border-radius: 10px;
}


.osb:hover .osbgif {
    transform:scale(100%);
    opacity: 1;
}

.kryptos:hover .kryptogif {
    transform:scale(100%);
    opacity: 1;
}

.laghmaribox:hover .lghmgif {
    transform:scale(100%);
    opacity: 1;
}

.lateralwrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.regosb {
    display: none;
    width:$mobile-wid;
    max-width: 500px;
    height:auto;
    padding:20px;
}

.Check-out {
    padding:10px;
    border-bottom: 4px solid black;
    display: flex;
    gap:10px;
    font-weight: 500;
    img {
        object-fit: cover;
        width:20px;
    }
}

.scrolltext {
    display: none;
    font-size: 14px;
}

.internal {
    opacity:0;
    animation: moveup 0.5s cubic-bezier(.14,.76,.21,.99) 0.5s 1 normal forwards;
}

.Work-title {
    font-size: 30px;
    font-weight: 600;
    font-family: "Mona-sans";
    padding-bottom: 10px;
}

.study {
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height:390px;
    margin-bottom: 20px;
    border-radius: 10px;
}

.osb {
    background-color: #C50636;
}

.kryptos {
    background-color: #1E1F4B;
}

.greenbrand {
    // background-color: #0E1E0D;
    background-color: #030416;
}

.deskonestep {
    display: none;
}

.Case-title {
    font-size: 20px;
    font-weight: 600;
    font-family: "Mona-sans";
    padding-bottom: 10px;
}

.Case-bread {
    font-size: 20px;
    font-weight: 500;
    font-family: "Mona-sans";
    padding-bottom: 20px;
    color:#585858;
}


.date {
    font-weight: 500;
    font-size:20px;
}

.divider {
    height:4px;
    width:100%;
    background-color:#D9D9D9;
}

.ptop {
margin-top: 40px;
}

.mtoplg {
    margin-top: 80px;
}

.mtopxlg {
    margin-top: 160px;
}

.ptoplg {
    padding-top: 80px;
}

.ptopxlg {
    padding-top: 120px;
}

.dndprojects {
margin-top: 80px;
}

.row-tags {
    display: flex;
    gap:10px;
    margin-bottom: 10px;
}

.row-last {
    padding-bottom: 20px;
}

.tag {
    border-radius: 1px;
    // border:1px solid #0C040F;
    padding:8px;
    border-radius: 10px;
    font-weight: 500;
    font-family: "Mona-sans";
    font-size: 14px;
}


.see-all-projects {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom:80px;
    padding-top: 40px;
}

.see-all-projects-button {
    display: flex;
    align-items: center;
    border-bottom: 4px solid black;
    padding:8px;
    gap:10px;
    font-weight: 500;
    font-size: 35px;
}


.ionarrow {
    width:30px;
    height:30px;
}

.small-indicator {
    display: none;
    font-size: 10px;
}

.services-title {
    font-size: 20px;
    padding-bottom: 40px;
} 

.servicesbox {
    width: 100%;
    height: 250px;
    margin-bottom: 20px;
    margin-top: 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow:hidden;
}

.devbg {
    background: radial-gradient(#e69f64, #91e59e);
} 

.desbg {
    background: radial-gradient(#e66465, #919ce5);
}

.resbg {
    background: radial-gradient(#64cae6, #9198e5);
}


.paddingbot {
    padding-bottom: 40px;
}

.area-text {
    font-size: 20px;
    padding-bottom: 20px;
}

.areas-arrow {
    display: none;
}

.areas-title {
    font-size: 30px;
    font-weight: 500;
    padding-bottom: 40px;
}

.areas-lateral {
    width: 100%;
}

.ab-leftside {
    display: none;
}

.areas-undertitel{
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 20px;
}

.areas-breadtext {
    font-size: 20px;
    color:#585858;
    padding-bottom: 100px;
    line-height: 140%;
}


/* Laghmari Box */

.lghmsection {
    flex-direction: column-reverse;
    padding-bottom: 100px;
}

.laghmaricontent {
    display:flex;
    flex-direction: column;
    align-items: flex-start;
}

.lghm-title {
    font-size: 40px;
    font-weight: 500;
    padding-bottom: 40px;
}

.lghm-bread {
    font-size: 20px;
    padding-bottom: 20px;
    line-height: 140%;
}

.lghm-tags {
    display: flex;
    gap:10px;
    padding-bottom: 40px;
}

.lghm-cta  {
    font-size: 20px;
    padding:8px;
    display: flex;
    font-weight: 500;
    gap:10px;
    align-items: center;
    border-bottom: 3px solid black;
    margin-bottom: 40px;
    img {
        width:20px;
        object-fit: cover;
    }
}

.fitwidt {
    width: fit-content;
    margin-bottom: 80px;
}

.laghmaribox {
    width:100%;
    height:400px;
    background-color: #060107;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(images/blurs/lghm-small.png);
}  

.blur-load {
    background-size: cover;
    background-position: center;
}

.blur-load.loaded .loadedbild  {
    opacity:1;
}

.blur-load .loadedbild {
    opacity: 0;
    transition: all .4s ease-out;
}

.lghmbild {
    width:100%;
    object-fit: cover;
    height:100%;
    border-radius: 10px;
}

.laghmaribox:hover .lghmbild {
    transform: scale(105%);
    filter:blur(8px);
}

/* Laghmari Box */

/* SERVICES */
.services-lg {
width: 100%;
display: flex;
justify-content: space-between;
align-items: flex-start;
gap:80px;
margin-bottom: 300px;
}

.services-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.services-big {
    font-size: 80px;
    font-weight: 700;
    line-height: 100%;
    color:#9b9b9b;
    transition: all .2s ease-out;
    cursor: pointer;
}

.services-big:hover {
color:black;
}

.gifbox {
    width:200px;
    height:100px;
    background-color: #000;
    margin-bottom: 10px;
    border-radius: 10px;
    overflow: hidden;
}

.gifboxtext {
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
    height: 100%;
}

.gifboximg {
    width: 100%;
    height: auto;
    animation: fadeIn .4s ease-in;
}

.gifboximgsmaller {
    width: 90%;
    height: auto;
}

.services-data {
padding-bottom: 20px;
}

.services-data-item {
    overflow: hidden;
    padding-bottom: 6px;
}

.services-cta {
    padding: 8px;
    border-bottom: 2px solid black;
    font-weight: 500;
    font-size: 20px;
    display: flex;
    gap:10px;
    img {
        width:20px;
        object-fit: cover;
    }
}

/* SERVICES */

/* Home page */

/* FOOTER */

footer {
    background-color: #060107;
    height:auto;
    width: 100%;
    color:white;
    display: flex;
    justify-content: center;
}

.love-to-hear {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap:20px;
    font-weight: 500;
    padding-bottom: 80px;
    border-bottom: 2px solid #828083;
}

.love-text {
    font-size: 30px;
}

.love-anch {
    font-size: 30px;
    color:white;
    text-decoration: underline;
}

.foot-site {
    display: flex;
    padding-top: 40px;
    padding-bottom: 80px;
    width: 100%;
}

.Foot-head {
    font-size: 30px;
}

.sitemap-links {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.s-links {
    font-size: 30px;
    color: #828083;
}

.sitemap-socials {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
}

.sitemap-soc {
    font-size: 30px;
    color:#828083;
}

.wordmarkfot {
    padding-bottom:20px;
    img {
        width:250px;
    }
}

.thanks {
    font-size: 20px;
    color:white;
    padding-bottom: 20px;
}

.flinks {
    display: flex;
    gap:20px;
    padding-bottom: 40px;
    a {
        color:white;
        font-size: 16px;
        text-decoration: underline;
    }
}

.lastupd {
    padding-bottom: 40px;
}

/* FOOTER */

/* WORK; ABOUT; SOCIALS */

.Work, .About, .casestudypage {
    min-height: 100vh;
}

.inside {
    display: none;
}

.greetingsabout-me {
    display: none;
}



/* WORK; ABOUT; SOCIALS */

/* WORK */
.Work {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.workpaddingtop {
    padding-top: 100px;
    padding-bottom: 20px;
}
.workpaddingtop2 {
    padding-top: 100px;
    padding-bottom: 20px;
}

.work-page {
    align-items: stretch;
}

.onestep-work {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}

.box-con {
    margin-top: 20px;
    width:100%;
    height:300px;
    background-color: #030416;
}

.work-info {
    padding-top: 20px;
}

.work-date {
    padding-bottom: 20px;
}

.more-projects {
    padding-bottom: 80px;
}

.project-item {
    border-bottom: 2px solid #D9D9D9;
    display: flex;
    justify-content: space-between;
    padding:10px 0px;
    flex-direction: column;
    gap:10px;
}

.projectsection {
    flex-grow: 1;
    flex-basis: 33%;
    color:#585858;
} 


.pstitle {
    font-weight: 500;
    color: #000;
}


.pitop {
    border-top: 2px solid #D9D9D9;
}

/* SOCIALS */
.Socials {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items:center;
}

.socialslinks {
    width: $mobile-wid;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 40px;
    gap:20px;
    font-weight: 700;
    font-family: "Mona-sans";
    color:black;
}  

.socialwrapper {
    overflow: hidden;
}

.socialup {
    display: block;
    opacity:0;
    animation: moveup 0.4s cubic-bezier(.14,.76,.21,.99) 0.5s 1 normal forwards;
} 

.mover1 {
    animation-delay: .2s;
}
.mover2 {
    animation-delay: .4s;
}
.mover3 {
    animation-delay: .6s;
}
.mover4 {
    animation-delay: .8s;
}

.deskservices {
    display: none;
}


/* MEDIA QUERIES */

@media screen and (max-width:400px) {
    .love-anch {
        font-size: 20px;
    }
}

@media screen and (min-width:600px) {
    .smallosb {
        display: none;
    } 
    .regosb {
        display: block;
    }
    .Case-bread {
        width: 100%;
    }

    .line-wrapper {
        overflow: hidden;
        padding-bottom: 10px;
    }

    .mobileservices {
    display: none;
    }

    .isacsmile {
        img {
            max-height: 330px;
        }
    } 
}

@media screen and (min-width:900px) {

    .fitwidt {
        display: none;;
    }

    .scrolltext {
        display: block;
    }

    .deskservices {
        display: flex;
    }

    .lateralwrapper {
        display: flex;
        flex-direction: row;
        gap:20px;
    }

    .study {
        height:380px;
    }

    .imgdefi {
        max-width: 500px;
    }

    .osbgif, .kryptogif, .lghmgif {
        width: 300px;
    }


    .small-indicator {
        display: block;
        font-size: 14px;
    }

    .smallonestep {
        display: none;
    }

    .deskonestep {
        width: 70%;
        max-width: 700px;
        display: block;
    }

    .hamburger {
        display: none;
    }

    .Navlink {
        display: block;
        font-size: 20px;
    }

    .greetingsabout-me {
        display: block;
        color:white;
        font-size: 20px;
        cursor: pointer;
        display: flex;
        gap:10px;
        margin-bottom:30px;
        font-weight: 600;
    }

    .Container-column {
        width:$mobile-wid;
    }

    .Home {
        padding-top: 120px;
    }
    .Hero-text {
        font-size: 80px;
    } 

    .Container-column {
        padding-top: 60px;
    }

    .Case-info {
        width: 100%;
    }

    .row-cta {
        display: flex;
        flex-direction: column-reverse;
        gap:10px;
        animation: fadeIn;
    }

    .row-last {
        padding-bottom: 10px;
    }

    .outside {
        display: none;
    }

    .inside {
        display: block;
    }

    .jcontentstart {
        display: flex;
        flex-direction: row;
        padding-top: 50px;
        align-items: flex-start;
        gap: 20px;
    }

    .axiscontainer {
        width:100%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
    }

    .col1, .flinks, .lastupd {
        flex-basis: 33%;
    }

    .socialslinks {
        font-size: 40px;
        flex-direction: row;
        justify-content: space-evenly;
     }

     .love-text, .love-anch {
    font-size: 50px;
     }

     .thanks {
        text-align: right;
     }

     .foot-site {
        max-width: 500px;
     }
     .area-text {
        width:30%;
     }
     .lwdiff {
        gap:0px;
     }
     .areas-title {
        font-size: 40px;
        max-width: 500px;
        padding-bottom: 80px;
     }

     .areas-undertitel, .areas-breadtext {
        margin-left: 30%;
        max-width: 600px;
     }


     .areas-arrow {
        display: block;
    }

    .areas-ab {
        display: none;
    }

     /* Laghmari Box */

    .lghmsection {
        justify-content: space-between;
        flex-direction: row;
        padding-bottom: 100px;
        gap:40px;
        align-items: center;
    }

    .laghmaricontent {
        width:50%;
    } 

    .laghmaribox {
        width:50%;
        height:600px;
    }  

    .lghm-cta {
        margin-bottom:0px;
    }

    .lghm-bread {
        font-size: 16px;
    }

    .services-data {
        padding-bottom: 40px;
    }


    /* Laghmari Box */

    .project-item {
        border-bottom: 2px solid #D9D9D9;
        display: flex;
        justify-content: space-between;
        padding:10px 0px;
        flex-direction: row;
        gap:10px;
    }



}

@media screen and (min-width:1200px) {

    .services-big {
        font-size: 100px;
        font-weight: 700;
        line-height: 100%;
        color:#9b9b9b;
        transition: all .2s ease-out;
        cursor: pointer;
    }

    .socialslinks {
        width:$medium-wid;
    }

    .Work-title {
        font-size: 50px;
    }

    .moblogo {
        display:none;
    }
    
    .desklogo {
        display: block;
    }

    .Container-column {
        width:$medium-wid;
    }

    .Hero-text {
        font-size: 100px;
    } 

    .line-wrapper {
        overflow: hidden;
        padding-bottom: 15px;
    }

    .herobild {
        max-height: 350px;
    }

    .hb-top {
        background-color: #36FF6E;
        transform: translate(-10px, 20px) rotate(-8deg);
    }

    .hb-top:hover {
        background-color: #36FF6E;
        transform: translate(-10px, 20px) rotate(-8deg) scale(105%);
    }

    .hb-bot {
        background-color: #ff5454;
        transform: translate(180px, 300px) rotate(-8deg);
    }

    .hb-bot:hover {
        background-color: #ff5454;
        transform: translate(180px, 300px) rotate(-8deg) scale(105%);
    }

    .jcontentstart {
        padding-top: 100px;
    }

    .topheadwid {
        width:$medium-wid;
    }

    .love-to-hear {
        padding-top: 0px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .sitemap-socials, .sitemap-links {
        gap:10px;
    }

    .gifbox {
        width:300px;
        height:150px;
        background-color: #000;
        margin-bottom: 10px;
        border-radius: 10px;
    }

    .osbgif, .kryptogif, .lghmgif {
        width: 400px;
    }

    .services-data {
        padding-bottom: 40px;
    }

    .isacstar {
        width:60px;
        height: 60px;
    }

    .starone {
        gap:20px;
    }

    .lghm-bread {
        font-size: 20px;
    }

}

@media screen and (min-width:1500px) {

    .laghmaribox {
        height: 500px;
    }

    .areas-title {
        font-size: 60px;
        max-width: 800px;
    }

    .areas-breadtext {
        font-size: 20px;
        line-height: 140%;
    }

    .services-big {
        font-size: 120px;
        font-weight: 700;
        line-height: 100%;
        color:#9b9b9b;
        transition: all .2s ease-out;
        cursor: pointer;
    }

    .socialslinks {
        width: $lg-wid;
        justify-content: space-evenly;
    }

    .Home {
        padding-top: 140px;
    }

    .line-wrapper {
        overflow: hidden;
        padding-bottom: 20px;
    }

    .topheadwid {
        width:$lg-wid;
        max-width: 1600px;
    }

    .study {
        height:400px;
    }
    
    .Container-column {
        padding-top: 120px;
        width:$lg-wid;
        max-width: 1600px;
    }

    .Hero-text {
        font-size: 160px;
        width:1000px;
        letter-spacing: -8px;
    } 

    .herobread {
        font-size: 30px;
        max-width: 50%;
        padding-bottom: 80px;
        line-height: 140%;
    }

    .isacstar {
        width:80px;
        height: 80px;
    }

    .starone {
        gap:40px;
    }

    .Check-out {
        font-size: 30px;
        gap:20px;

        img {
            width: 40px;
        }
    }

    .scrolltext {
        font-size: 20px;
    }
    

    .herobild {
        max-height: 500px;
        width:375px;
    }

    .stretchclass {
        padding-bottom: 80px;
    } 

    .Work-title {
        font-size: 60px;
        font-weight: 700;
    }

    .Case-title {
        font-size: 30px;
        font-weight: 700;
    }

    .Case-bread {
        width: 70%;
    }

    .isacsmile {
        img {
            max-height: 400px;
        }
    }

    .inside {
        max-width: 70%;
    }

    .hero-button {
        font-size: 30px;
    }

    .hb-bot {
        background-color: #ff5454;
        transform: translate(280px, 430px) rotate(-8deg);
    }

    .hb-bot:hover {
        background-color: #ff5454;
        transform: translate(280px, 430px) rotate(-8deg) scale(105%);
    }

    .jcontentstart {
        padding-top: 100px;
    }

    .workpaddingtop {
        padding-top: 0px;
    }

    .gifbox {
        width:400px;
        height:200px;
        background-color: #000;
        margin-bottom: 10px;
        border-radius: 10px;
    }

    .services-data {
        padding-bottom: 80px;
    }

    .services-data-item {
        font-size: 20px;
    }

}

.stretchclass {
    justify-content: center;
    gap:40px;
    padding-top: 0px;
}

.default-active {
    color: black;
  }

  .activebuh {
    color: black;
  }

