* {
  margin: 0;
  padding: 0;
} 

@font-face {
  font-family: 'Mona-sans';
  src:
    url('Mona-Sans.woff2') format('woff2 supports variations'),
    url('Mona-Sans.woff2') format('woff2-variations');
  font-weight: 200 300 400 500 600 700;
  font-stretch: 75% 125%;
}

@font-face {
  font-family: 'FreeSans';
  src:
  url('Free Sans Bold.woff2') format('woff2 supports variations'),
  url('Free Sans Bold.woff2') format('woff2-variations');
    font-weight: 700;
  font-stretch: 75% 125%;
}

body {
  font-family:"Mona-sans", 'Inter', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Inter', 'Courier New', source-code-pro, Menlo, Monaco, Consolas
    monospace;
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}