@font-face {
  font-family: "Mona-sans";
  src: url("Mona-Sans.woff2") format("woff2 supports variations"), url("Mona-Sans.woff2") format("woff2-variations");
  font-weight: 200 300 400 500 600 700;
}
@font-face {
  font-family: "FreeSans";
  src: url("Free Sans Bold.woff2") format("woff2 supports variations"), url("Free Sans Bold.woff2") format("woff2-variations");
  font-weight: 700;
}
@keyframes moveup {
  from {
    opacity: 0;
    transform: translate3d(0px, 300%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
  }
  to {
    opacity: 1;
    transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  }
}
@-webkit-keyframes moveup {
  from {
    opacity: 0;
    transform: translate3d(0px, 300%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
  }
  to {
    opacity: 1;
    transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  }
}
@-moz-keyframes moveup {
  from {
    opacity: 0;
    transform: translate3d(0px, 300%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
  }
  to {
    opacity: 1;
    transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes moveText {
  0%, 100% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(100%);
  }
  51% {
    transform: translateX(-100%);
  }
}
.About {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-title {
  font-size: 130px;
  line-height: 95%;
  font-family: "FreeSans";
}

.about-text-img {
  display: flex;
  justify-content: space-between;
}

.ab-left, .ab-right {
  width: 50%;
}

.ab-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ab-passions {
  display: flex;
  justify-content: space-between;
}

.pas-left, .pas-right {
  width: 50%;
}

.pas-title {
  font-size: 30px;
  margin-bottom: 10px;
  font-weight: 500;
}

.pas-item {
  margin-bottom: 10px;
}

.ab-right {
  height: 1000px;
  display: none;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.ab-bread {
  font-weight: 500;
  font-size: 40px;
  line-height: 110%;
  padding-top: 40px;
}

.abo-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.abo-img-desk {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ab-b {
  padding-bottom: 200px;
}

.threerow {
  display: flex;
  gap: 40px;
  height: auto;
}

.ab-exp {
  height: auto;
  flex-grow: 1;
}

.exp-num {
  font-size: 60px;
  font-weight: 500;
}

.e-border {
  border-bottom: 4px solid #ECECEC;
}

.exp-tags {
  display: flex;
  gap: 10px;
  padding: 10px 0px;
}

.exp-tag {
  background-color: grey;
  padding: 4px;
  border-radius: 8px;
  font-weight: 500;
}

.exp-des {
  background-color: #8CF1FF;
}

.exp-dev {
  background-color: #FFA8A8;
}

.exp-co {
  background-color: #48f1ab;
}

.exp-devdev {
  background-color: #D1FF4D;
}

.exp-int {
  background-color: #daa6ff;
}

.exp-title {
  display: block;
  padding: 10px 0px;
  font-size: 30px;
  font-weight: 600;
  width: 100%;
}

.exp-date {
  padding: 10px 0px;
}

.exp-place {
  padding: 10px 0px;
}

.exp-url {
  padding: 10px 0px;
  font-weight: 500;
}

.exp-bread {
  font-weight: 500;
  padding: 10px 0px;
}

.mobionly {
  display: block;
}

.abo-div-mob {
  margin-top: 40px;
  background-image: url(images/blurs/usacsmileinv-min-small.png);
}

.abo-div {
  width: 70%;
  height: 500px;
  background-image: url(images/blurs/usacsmileinv-min-small.png);
}

@media screen and (max-width: 600px) {
  .about-title {
    padding-top: 20px;
    font-size: 40px;
  }
  .mobionly {
    display: block;
  }
  .ab-left {
    width: 100%;
  }
  .ab-right {
    display: none;
  }
  .ab-bread {
    font-size: 20px;
  }
  .abo-img {
    width: 100%;
    height: 360px;
  }
  .ab-passions {
    padding-top: 40px;
  }
  .pas-title {
    font-size: 20px;
  }
  .pas-item {
    font-weight: 500;
  }
  .threerow {
    padding-top: 40px;
    flex-direction: column;
  }
}
@media screen and (min-width: 600px) {
  .about-title {
    padding-top: 40px;
    font-size: 50px;
  }
  .mobionly {
    display: none;
  }
  .ab-bread {
    font-size: 20px;
  }
  .pas-title {
    font-size: 20px;
  }
  .pas-item {
    font-weight: 500;
  }
  .ab-right {
    display: flex;
    height: 800px;
  }
  .abo-img {
    height: 400px;
    width: auto;
  }
  .threerow {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 900px) {
  .about-title {
    font-size: 80px;
  }
  .abo-div-mob {
    display: none;
  }
  .mobionly {
    display: none;
  }
  .ab-bread {
    font-size: 30px;
  }
  .pas-title {
    font-size: 20px;
  }
  .ab-right {
    display: flex;
    height: 700px;
  }
  .abo-img {
    height: 500px;
  }
  .threerow {
    padding-top: 40px;
    flex-wrap: nowrap;
  }
  .ab-exp {
    width: 50%;
    flex-grow: 0;
  }
  .exp-tags {
    flex-wrap: wrap;
  }
  .small-indicator {
    font-size: 20px;
  }
}
@media screen and (min-width: 1500px) {
  .about-title {
    padding-top: 0px;
    font-size: 120px;
  }
}
.casestudypage {
  display: flex;
  justify-content: center;
}

.case-oversight {
  font-size: 20px;
  color: #747E86;
  line-height: 110%;
  padding-bottom: 20px;
  padding-top: 20px;
  font-weight: 500;
  width: 100%;
}

.case-header {
  font-size: 40px;
  font-weight: 500;
  line-height: 110%;
}

.hed-hanger {
  width: 100%;
  object-fit: cover;
}

.header-img-osb {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.case-header-img {
  width: 100%;
  margin-bottom: 40px;
}

.case-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 160px;
}

.case-basics {
  font-size: 30px;
  color: #747E86;
  font-weight: 400;
  padding-bottom: 20px;
  line-height: 110%;
  width: 100%;
}

.casestudy-basics {
  width: 100%;
}

.casestudy-bread {
  font-size: 20px;
  padding-bottom: 40px;
  line-height: 140%;
}

.casestudy-section-title {
  font-size: 40px;
  line-height: 110%;
  padding-bottom: 40px;
  font-weight: 500;
  width: 100%;
}

.number-indicator {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 3px solid #C50636;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 20px;
}

.nb-dark {
  border: 3px solid #000;
}

.casestudy-undertitle {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 20px;
  width: 100%;
}

.csunderlg {
  font-size: 30px;
}

.casestudy-cta {
  text-decoration: underline;
  font-size: 20px;
  font-weight: 500;
}

.massive-img-osb {
  object-fit: cover;
  max-height: 500px;
}

.heroborder {
  padding-bottom: 10px;
  border-bottom: 3px solid #D9D9D9;
  margin-bottom: 10px;
  gap: 10px;
}

.case-lateral {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.case-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.case-box-img-smaller {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.case-box-img-smaller img {
  object-fit: cover;
  height: 100%;
  max-width: 80%;
  padding: 20px;
}

/* OneStepBeyond */
.osb-overviewtext {
  font-size: 20px;
  line-height: 140%;
  width: 100%;
  padding-bottom: 40px;
}

.hed-hanger {
  height: 100%;
}

.hangerdiv {
  flex-grow: 1;
  border-radius: 10px;
  background-image: url(images/blurs/hangers-small.png);
  overflow: hidden;
  width: 100%;
}

.osbdiv {
  border-radius: 10px;
  background-image: url(images/blurs/osbcase-small.png);
  width: 100%;
  overflow: hidden;
}

/*Laghmari */
.laghmari-body {
  min-height: 100vh;
}

.lghm-header-bild {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.lghm-box {
  height: 200px;
}

.lghm-lateral {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.titel-lateral {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 80px;
}

.titel-s {
  font-size: 20px;
  font-weight: 500;
}

.Laghmari-titel {
  font-size: 60px;
  font-weight: 700;
}

.titel-b {
  font-size: 60px;
  font-weight: 500;
}

.mobile-lghm-prelude {
  font-size: 30px;
  font-weight: 500;
  padding-bottom: 20px;
}

.lghm-prelude {
  font-size: 20px;
}

.lghms-basics {
  font-size: 30px;
  padding-bottom: 10px;
}

.lghm-bread-text {
  font-size: 20px;
  padding-bottom: 80px;
}

.lghm-tag {
  width: fit-content;
  padding: 8px;
  font-size: 16px;
  line-height: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
}

.lghm-content-box {
  width: 100%;
}

.lghm-UI-bild {
  width: 100%;
  padding-bottom: 20px;
}

.desk-cool-bild {
  display: none;
}

.cool-lghmbild {
  width: 100%;
}

.maincolor {
  width: 100%;
  height: 200px;
  background-color: #72876A;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.othercolor {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.darkblue {
  background-color: #000214;
}

.lightwhite {
  background-color: #F2FDFF;
}

.darkgreen {
  background-color: #4C5E56;
}

.lghmyellow {
  background-color: #F4D06F;
}

.warningorange {
  background-color: #FF8811;
}

.lghm-colors-padding {
  padding-top: 20px;
  padding-left: 20px;
  font-size: 40px;
  font-weight: 500;
}

.color-code-white {
  font-size: 20px;
  color: white;
  opacity: 0.75;
  padding-left: 20px;
  padding-bottom: 20px;
}

.color-code-dark {
  font-size: 20px;
  color: black;
  opacity: 0.75;
  padding-left: 20px;
  padding-bottom: 20px;
}

.colors-gap {
  gap: 0px;
}

.lghmpromoimg {
  width: 100%;
}

.lghmvanurl {
  background-image: url(images/blurs/lghmvan-small.png);
}

.lghmoffice {
  background-image: url(images/blurs/lghmoffice-small.png);
}

.promo-left {
  width: 100%;
}

.promo-right {
  width: 100%;
}

/* Krypto */
.havebeforebox {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #D9D9D9;
  margin-bottom: 10px;
}
.havebeforebox img {
  width: 70%;
  object-fit: cover;
  max-height: 100%;
}

.havebeforebg {
  background-color: #D9D9D9;
}

.hbpadd {
  padding-bottom: 40px;
  max-width: 80%;
}

.krypto-lateral {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.krypto-header {
  width: 100%;
}

.outcome-box {
  width: 80px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
.outcome-box img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.case-header-krypto-div {
  overflow: hidden;
  width: 100%;
  height: 500px;
  border-radius: 10px;
  background-position: center;
  background-size: cover;
  background-image: url(images/blurs/kryptos4-small.png);
}

.case-header-krypto {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.scrolltext-kryptocase {
  text-align: right;
  width: 100%;
}

.lghmgap {
  gap: 0px;
}

.desk-lghm-landing {
  display: none;
}

.biglogo {
  display: none;
}

.hbboxgap {
  gap: 0px;
}

@media screen and (max-width: 375px) {
  .Laghmari-titel {
    font-size: 40px;
  }
}
@media screen and (min-width: 900px) {
  .hbboxgap {
    gap: 20px;
  }
  .case-oversight {
    padding-bottom: 0px;
  }
  .osb-overviewtext {
    font-size: 30px;
    line-height: 140%;
    width: 70%;
    padding-bottom: 40px;
  }
  .case-section {
    margin-bottom: 200px;
  }
  .hangerdiv {
    max-width: 60%;
  }
  .case-lateral {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
  }
  .case-oversight {
    padding-top: 60px;
  }
  .case-header {
    font-size: 50px;
  }
  .heroborder {
    padding-bottom: 10px;
    border-bottom: 3px solid #D9D9D9;
    margin-bottom: 10px;
    gap: 10px;
  }
  .case-header-img {
    width: 50%;
    height: auto;
    object-fit: cover;
  }
  .hed-hanger {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  .header-img-osb {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  .box-x-axis {
    align-items: center;
  }
  .case-basics {
    width: 40%;
    min-width: 140px;
  }
  .casestudy-bread-intro {
    font-size: 20px;
    line-height: 140%;
    width: 70%;
  }
  .goal {
    flex-basis: 33%;
  }
  .testareas {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .case-box {
    width: 50%;
    height: 100%;
  }
  .case-box img {
    width: 100%;
  }
  .case-box-img-smaller {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .case-box-img-smaller img {
    width: 70%;
  }
  .test-findings-wid {
    width: 70%;
  }
  /*KRYPTOSKATT */
  .krypto-lateral {
    display: flex;
    flex-direction: row-reverse;
    gap: 40px;
    padding-top: 40px;
  }
  .krypto-header {
    font-size: 40px;
    line-height: 110%;
    padding-top: 0px;
  }
  .kryptobox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .kryptooversight {
    padding-top: 0px;
  }
  .havebeforebox {
    height: 350px;
    margin-bottom: 20px;
  }
  .havebeforebox img {
    width: 70%;
    object-fit: cover;
    max-height: 100%;
  }
  /* LAGHMARI */
  .lghm-box {
    width: 50%;
    height: 400px;
  }
  .lghm-box-lower {
    width: 50%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .titel-lateral {
    color: #FFF;
    margin-bottom: 0px;
    padding: 0px 10px 10px;
  }
  .lghmgap {
    gap: 0px;
  }
  .mobile-lghm-box {
    display: none;
  }
  .Laghmari-titel {
    padding-right: 10px;
    font-size: 30px;
    font-weight: 700;
    color: white;
    text-align: right;
  }
  .titel-s {
    font-size: 20px;
  }
  .titel-b {
    font-size: 20px;
  }
  .mobile-lghm-prelude {
    padding-bottom: 10px;
    font-weight: 700;
  }
  .datesrow1 {
    width: 50%;
    display: flex;
    justify-content: space-between;
  }
  .first-step,
  .second-step {
    color: black;
    padding-top: 10px;
  }
  .third-step {
    color: white;
    padding-top: 10px;
    padding-left: 10px;
  }
  .biglogo {
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
  }
  .biglogo img {
    width: 100%;
  }
  .colors-lateral {
    display: flex;
    height: 200px;
  }
  .othercolor {
    height: 200px;
  }
  .othercolor-lastsection {
    width: 100%;
  }
  .warningorange, .lghmyellow {
    height: 100px;
  }
  .maincolor {
    height: 200px;
  }
  .desk-cool-bild {
    display: block;
  }
  .promo-right {
    width: 70%;
  }
  .promo-left {
    width: 30%;
  }
}
@media screen and (min-width: 1200px) {
  .casestudy-section-title {
    padding-bottom: 60px;
  }
  .case-oversight {
    padding-top: 40px;
  }
  .kryptooversight {
    padding-top: 0px;
  }
  .case-header {
    font-weight: 500;
    font-size: 50px;
  }
  .casestudy-bread-intro {
    font-size: 30px;
  }
  .havebeforebox {
    height: 400px;
    margin-bottom: 20px;
  }
  .havebeforebox img {
    width: 70%;
    object-fit: cover;
    max-height: 100%;
  }
  .hangerdiv {
    width: auto;
  }
  .osbdiv {
    width: 40%;
  }
  .case-lateral-lghm-lg {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    gap: 20px;
  }
  .hbboxgap {
    gap: 20px;
  }
}
@media screen and (min-width: 1500px) {
  .casestudy-section-title {
    padding-bottom: 80px;
  }
  .case-oversight {
    padding-top: 20px;
  }
  .case-header {
    max-width: 1200px;
    font-weight: 500;
    font-size: 60px;
  }
  .casestudy-bread-intro {
    font-size: 30px;
    font-weight: 500;
    line-height: 140%;
  }
  .hangerdiv,
  .osbdiv {
    height: 350px;
  }
  .casestudy-bread {
    font-size: 20px;
  }
  .havebeforebox {
    height: 500px;
    margin-bottom: 20px;
  }
  .havebeforebox img {
    width: 70%;
    object-fit: cover;
    max-height: 100%;
  }
  .osb-overviewtext {
    font-size: 40px;
    margin-bottom: 80px;
  }
  .pbfixed {
    padding-bottom: 20px;
  }
  .hbboxgap {
    gap: 20px;
  }
}
body {
  height: auto;
}

a {
  text-decoration: none;
  color: black;
}

.Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 30px);
  height: 100%;
}

.Container-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 80px;
  width: calc(100% - 30px);
}

.button-effect, .button-effect-dark {
  position: relative;
  padding-bottom: 4px;
}

.button-effect:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: #000;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.button-effect-dark:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: #FFF;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.button-effect:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.button-effect-dark:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* Header */
header {
  font-weight: bold;
  width: 100%;
  height: auto;
  min-height: 75px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  transition: top 0.2s ease-in-out;
  z-index: 2;
}

.nav-up {
  top: -75px;
}

.ptophead {
  margin-top: 10px;
}

.desklogo {
  display: none;
  width: 100px;
}

.moblogo {
  width: 100px;
}

nav {
  display: flex;
  gap: 30px;
}

.Navlink {
  display: none;
}

.hamburger {
  display: flex;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;
  z-index: 4;
}

.bun-top {
  width: 25px;
  height: 4px;
  background-color: black;
  border-radius: 2px;
  transition: all 0.2s ease-in-out;
}

.bun-mid {
  width: 25px;
  height: 4px;
  background-color: black;
  border-radius: 2px;
  transition: all 0.2s ease-in-out;
}

.bun-bot {
  width: 25px;
  height: 4px;
  background-color: black;
  border-radius: 2px;
  transition: all 0.2s ease-in-out;
}

.side-menu {
  top: -100vh;
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #060107;
  right: 0;
  transition: all 0.4s ease-in-out;
  z-index: 3; /* Increase the z-index value */
}

.sidemenucontainer {
  width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 100px;
  padding-left: 20px;
}

.sidemenulinks {
  color: white;
  font-weight: 600;
  font-size: 40px;
  text-align: right;
}

.visible {
  display: none;
  top: 0;
}

.whitebun {
  background-color: white;
}

/* Header */
/* Home page */
.Home {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Hero-text {
  font-family: "FreeSans";
  font-weight: 700;
  font-size: 50px;
  line-height: 0.9;
  letter-spacing: -3px;
}

.line-wrapper {
  overflow: hidden;
  padding-bottom: 10px;
}

.starone {
  display: flex;
  gap: 15px;
  align-items: center;
}

.isacstar {
  width: 40px;
  height: auto;
}

.herobread {
  font-size: 20px;
  width: 100%;
  max-width: 600px;
  font-weight: 500;
}

.herocta {
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.imgdefi {
  width: 300px;
  height: 320px;
  object-fit: cover;
  transition: all 0.3s ease-out;
}

.imgdefi:hover {
  transform: scale(95%);
  filter: blur(3px);
}

.osbgif, .kryptogif, .lghmgif {
  opacity: 0;
  width: 300px;
  height: auto;
  object-fit: cover;
  position: absolute;
  z-index: 1;
  pointer-events: none;
  transform: scale(95%);
  transition: all 0.4s ease-out;
  border-radius: 10px;
}

.osb:hover .osbgif {
  transform: scale(100%);
  opacity: 1;
}

.kryptos:hover .kryptogif {
  transform: scale(100%);
  opacity: 1;
}

.laghmaribox:hover .lghmgif {
  transform: scale(100%);
  opacity: 1;
}

.lateralwrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.regosb {
  display: none;
  width: calc(100% - 30px);
  max-width: 500px;
  height: auto;
  padding: 20px;
}

.Check-out {
  padding: 10px;
  border-bottom: 4px solid black;
  display: flex;
  gap: 10px;
  font-weight: 500;
}
.Check-out img {
  object-fit: cover;
  width: 20px;
}

.scrolltext {
  display: none;
  font-size: 14px;
}

.internal {
  opacity: 0;
  animation: moveup 0.5s cubic-bezier(0.14, 0.76, 0.21, 0.99) 0.5s 1 normal forwards;
}

.Work-title {
  font-size: 30px;
  font-weight: 600;
  font-family: "Mona-sans";
  padding-bottom: 10px;
}

.study {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 390px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.osb {
  background-color: #C50636;
}

.kryptos {
  background-color: #1E1F4B;
}

.greenbrand {
  background-color: #030416;
}

.deskonestep {
  display: none;
}

.Case-title {
  font-size: 20px;
  font-weight: 600;
  font-family: "Mona-sans";
  padding-bottom: 10px;
}

.Case-bread {
  font-size: 20px;
  font-weight: 500;
  font-family: "Mona-sans";
  padding-bottom: 20px;
  color: #585858;
}

.date {
  font-weight: 500;
  font-size: 20px;
}

.divider {
  height: 4px;
  width: 100%;
  background-color: #D9D9D9;
}

.ptop {
  margin-top: 40px;
}

.mtoplg {
  margin-top: 80px;
}

.mtopxlg {
  margin-top: 160px;
}

.ptoplg {
  padding-top: 80px;
}

.ptopxlg {
  padding-top: 120px;
}

.dndprojects {
  margin-top: 80px;
}

.row-tags {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.row-last {
  padding-bottom: 20px;
}

.tag {
  border-radius: 1px;
  padding: 8px;
  border-radius: 10px;
  font-weight: 500;
  font-family: "Mona-sans";
  font-size: 14px;
}

.see-all-projects {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 80px;
  padding-top: 40px;
}

.see-all-projects-button {
  display: flex;
  align-items: center;
  border-bottom: 4px solid black;
  padding: 8px;
  gap: 10px;
  font-weight: 500;
  font-size: 35px;
}

.ionarrow {
  width: 30px;
  height: 30px;
}

.small-indicator {
  display: none;
  font-size: 10px;
}

.services-title {
  font-size: 20px;
  padding-bottom: 40px;
}

.servicesbox {
  width: 100%;
  height: 250px;
  margin-bottom: 20px;
  margin-top: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.devbg {
  background: radial-gradient(#e69f64, #91e59e);
}

.desbg {
  background: radial-gradient(#e66465, #919ce5);
}

.resbg {
  background: radial-gradient(#64cae6, #9198e5);
}

.paddingbot {
  padding-bottom: 40px;
}

.area-text {
  font-size: 20px;
  padding-bottom: 20px;
}

.areas-arrow {
  display: none;
}

.areas-title {
  font-size: 30px;
  font-weight: 500;
  padding-bottom: 40px;
}

.areas-lateral {
  width: 100%;
}

.ab-leftside {
  display: none;
}

.areas-undertitel {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 20px;
}

.areas-breadtext {
  font-size: 20px;
  color: #585858;
  padding-bottom: 100px;
  line-height: 140%;
}

/* Laghmari Box */
.lghmsection {
  flex-direction: column-reverse;
  padding-bottom: 100px;
}

.laghmaricontent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.lghm-title {
  font-size: 40px;
  font-weight: 500;
  padding-bottom: 40px;
}

.lghm-bread {
  font-size: 20px;
  padding-bottom: 20px;
  line-height: 140%;
}

.lghm-tags {
  display: flex;
  gap: 10px;
  padding-bottom: 40px;
}

.lghm-cta {
  font-size: 20px;
  padding: 8px;
  display: flex;
  font-weight: 500;
  gap: 10px;
  align-items: center;
  border-bottom: 3px solid black;
  margin-bottom: 40px;
}
.lghm-cta img {
  width: 20px;
  object-fit: cover;
}

.fitwidt {
  width: fit-content;
  margin-bottom: 80px;
}

.laghmaribox {
  width: 100%;
  height: 400px;
  background-color: #060107;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(images/blurs/lghm-small.png);
}

.blur-load {
  background-size: cover;
  background-position: center;
}

.blur-load.loaded .loadedbild {
  opacity: 1;
}

.blur-load .loadedbild {
  opacity: 0;
  transition: all 0.4s ease-out;
}

.lghmbild {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 10px;
}

.laghmaribox:hover .lghmbild {
  transform: scale(105%);
  filter: blur(8px);
}

/* Laghmari Box */
/* SERVICES */
.services-lg {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 80px;
  margin-bottom: 300px;
}

.services-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.services-big {
  font-size: 80px;
  font-weight: 700;
  line-height: 100%;
  color: #9b9b9b;
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.services-big:hover {
  color: black;
}

.gifbox {
  width: 200px;
  height: 100px;
  background-color: #000;
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
}

.gifboxtext {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 100%;
}

.gifboximg {
  width: 100%;
  height: auto;
  animation: fadeIn 0.4s ease-in;
}

.gifboximgsmaller {
  width: 90%;
  height: auto;
}

.services-data {
  padding-bottom: 20px;
}

.services-data-item {
  overflow: hidden;
  padding-bottom: 6px;
}

.services-cta {
  padding: 8px;
  border-bottom: 2px solid black;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  gap: 10px;
}
.services-cta img {
  width: 20px;
  object-fit: cover;
}

/* SERVICES */
/* Home page */
/* FOOTER */
footer {
  background-color: #060107;
  height: auto;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
}

.love-to-hear {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  font-weight: 500;
  padding-bottom: 80px;
  border-bottom: 2px solid #828083;
}

.love-text {
  font-size: 30px;
}

.love-anch {
  font-size: 30px;
  color: white;
  text-decoration: underline;
}

.foot-site {
  display: flex;
  padding-top: 40px;
  padding-bottom: 80px;
  width: 100%;
}

.Foot-head {
  font-size: 30px;
}

.sitemap-links {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.s-links {
  font-size: 30px;
  color: #828083;
}

.sitemap-socials {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}

.sitemap-soc {
  font-size: 30px;
  color: #828083;
}

.wordmarkfot {
  padding-bottom: 20px;
}
.wordmarkfot img {
  width: 250px;
}

.thanks {
  font-size: 20px;
  color: white;
  padding-bottom: 20px;
}

.flinks {
  display: flex;
  gap: 20px;
  padding-bottom: 40px;
}
.flinks a {
  color: white;
  font-size: 16px;
  text-decoration: underline;
}

.lastupd {
  padding-bottom: 40px;
}

/* FOOTER */
/* WORK; ABOUT; SOCIALS */
.Work, .About, .casestudypage {
  min-height: 100vh;
}

.inside {
  display: none;
}

.greetingsabout-me {
  display: none;
}

/* WORK; ABOUT; SOCIALS */
/* WORK */
.Work {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.workpaddingtop {
  padding-top: 100px;
  padding-bottom: 20px;
}

.workpaddingtop2 {
  padding-top: 100px;
  padding-bottom: 20px;
}

.work-page {
  align-items: stretch;
}

.onestep-work {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.box-con {
  margin-top: 20px;
  width: 100%;
  height: 300px;
  background-color: #030416;
}

.work-info {
  padding-top: 20px;
}

.work-date {
  padding-bottom: 20px;
}

.more-projects {
  padding-bottom: 80px;
}

.project-item {
  border-bottom: 2px solid #D9D9D9;
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  flex-direction: column;
  gap: 10px;
}

.projectsection {
  flex-grow: 1;
  flex-basis: 33%;
  color: #585858;
}

.pstitle {
  font-weight: 500;
  color: #000;
}

.pitop {
  border-top: 2px solid #D9D9D9;
}

/* SOCIALS */
.Socials {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.socialslinks {
  width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 40px;
  gap: 20px;
  font-weight: 700;
  font-family: "Mona-sans";
  color: black;
}

.socialwrapper {
  overflow: hidden;
}

.socialup {
  display: block;
  opacity: 0;
  animation: moveup 0.4s cubic-bezier(0.14, 0.76, 0.21, 0.99) 0.5s 1 normal forwards;
}

.mover1 {
  animation-delay: 0.2s;
}

.mover2 {
  animation-delay: 0.4s;
}

.mover3 {
  animation-delay: 0.6s;
}

.mover4 {
  animation-delay: 0.8s;
}

.deskservices {
  display: none;
}

/* MEDIA QUERIES */
@media screen and (max-width: 400px) {
  .love-anch {
    font-size: 20px;
  }
}
@media screen and (min-width: 600px) {
  .smallosb {
    display: none;
  }
  .regosb {
    display: block;
  }
  .Case-bread {
    width: 100%;
  }
  .line-wrapper {
    overflow: hidden;
    padding-bottom: 10px;
  }
  .mobileservices {
    display: none;
  }
  .isacsmile img {
    max-height: 330px;
  }
}
@media screen and (min-width: 900px) {
  .fitwidt {
    display: none;
  }
  .scrolltext {
    display: block;
  }
  .deskservices {
    display: flex;
  }
  .lateralwrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .study {
    height: 380px;
  }
  .imgdefi {
    max-width: 500px;
  }
  .osbgif, .kryptogif, .lghmgif {
    width: 300px;
  }
  .small-indicator {
    display: block;
    font-size: 14px;
  }
  .smallonestep {
    display: none;
  }
  .deskonestep {
    width: 70%;
    max-width: 700px;
    display: block;
  }
  .hamburger {
    display: none;
  }
  .Navlink {
    display: block;
    font-size: 20px;
  }
  .greetingsabout-me {
    display: block;
    color: white;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    gap: 10px;
    margin-bottom: 30px;
    font-weight: 600;
  }
  .Container-column {
    width: calc(100% - 30px);
  }
  .Home {
    padding-top: 120px;
  }
  .Hero-text {
    font-size: 80px;
  }
  .Container-column {
    padding-top: 60px;
  }
  .Case-info {
    width: 100%;
  }
  .row-cta {
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
    animation: fadeIn;
  }
  .row-last {
    padding-bottom: 10px;
  }
  .outside {
    display: none;
  }
  .inside {
    display: block;
  }
  .jcontentstart {
    display: flex;
    flex-direction: row;
    padding-top: 50px;
    align-items: flex-start;
    gap: 20px;
  }
  .axiscontainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
  .col1, .flinks, .lastupd {
    flex-basis: 33%;
  }
  .socialslinks {
    font-size: 40px;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .love-text, .love-anch {
    font-size: 50px;
  }
  .thanks {
    text-align: right;
  }
  .foot-site {
    max-width: 500px;
  }
  .area-text {
    width: 30%;
  }
  .lwdiff {
    gap: 0px;
  }
  .areas-title {
    font-size: 40px;
    max-width: 500px;
    padding-bottom: 80px;
  }
  .areas-undertitel, .areas-breadtext {
    margin-left: 30%;
    max-width: 600px;
  }
  .areas-arrow {
    display: block;
  }
  .areas-ab {
    display: none;
  }
  /* Laghmari Box */
  .lghmsection {
    justify-content: space-between;
    flex-direction: row;
    padding-bottom: 100px;
    gap: 40px;
    align-items: center;
  }
  .laghmaricontent {
    width: 50%;
  }
  .laghmaribox {
    width: 50%;
    height: 600px;
  }
  .lghm-cta {
    margin-bottom: 0px;
  }
  .lghm-bread {
    font-size: 16px;
  }
  .services-data {
    padding-bottom: 40px;
  }
  /* Laghmari Box */
  .project-item {
    border-bottom: 2px solid #D9D9D9;
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    flex-direction: row;
    gap: 10px;
  }
}
@media screen and (min-width: 1200px) {
  .services-big {
    font-size: 100px;
    font-weight: 700;
    line-height: 100%;
    color: #9b9b9b;
    transition: all 0.2s ease-out;
    cursor: pointer;
  }
  .socialslinks {
    width: calc(100% - 200px);
  }
  .Work-title {
    font-size: 50px;
  }
  .moblogo {
    display: none;
  }
  .desklogo {
    display: block;
  }
  .Container-column {
    width: calc(100% - 200px);
  }
  .Hero-text {
    font-size: 100px;
  }
  .line-wrapper {
    overflow: hidden;
    padding-bottom: 15px;
  }
  .herobild {
    max-height: 350px;
  }
  .hb-top {
    background-color: #36FF6E;
    transform: translate(-10px, 20px) rotate(-8deg);
  }
  .hb-top:hover {
    background-color: #36FF6E;
    transform: translate(-10px, 20px) rotate(-8deg) scale(105%);
  }
  .hb-bot {
    background-color: #ff5454;
    transform: translate(180px, 300px) rotate(-8deg);
  }
  .hb-bot:hover {
    background-color: #ff5454;
    transform: translate(180px, 300px) rotate(-8deg) scale(105%);
  }
  .jcontentstart {
    padding-top: 100px;
  }
  .topheadwid {
    width: calc(100% - 200px);
  }
  .love-to-hear {
    padding-top: 0px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .sitemap-socials, .sitemap-links {
    gap: 10px;
  }
  .gifbox {
    width: 300px;
    height: 150px;
    background-color: #000;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  .osbgif, .kryptogif, .lghmgif {
    width: 400px;
  }
  .services-data {
    padding-bottom: 40px;
  }
  .isacstar {
    width: 60px;
    height: 60px;
  }
  .starone {
    gap: 20px;
  }
  .lghm-bread {
    font-size: 20px;
  }
}
@media screen and (min-width: 1500px) {
  .laghmaribox {
    height: 500px;
  }
  .areas-title {
    font-size: 60px;
    max-width: 800px;
  }
  .areas-breadtext {
    font-size: 20px;
    line-height: 140%;
  }
  .services-big {
    font-size: 120px;
    font-weight: 700;
    line-height: 100%;
    color: #9b9b9b;
    transition: all 0.2s ease-out;
    cursor: pointer;
  }
  .socialslinks {
    width: calc(100% - 400px);
    justify-content: space-evenly;
  }
  .Home {
    padding-top: 140px;
  }
  .line-wrapper {
    overflow: hidden;
    padding-bottom: 20px;
  }
  .topheadwid {
    width: calc(100% - 400px);
    max-width: 1600px;
  }
  .study {
    height: 400px;
  }
  .Container-column {
    padding-top: 120px;
    width: calc(100% - 400px);
    max-width: 1600px;
  }
  .Hero-text {
    font-size: 160px;
    width: 1000px;
    letter-spacing: -8px;
  }
  .herobread {
    font-size: 30px;
    max-width: 50%;
    padding-bottom: 80px;
    line-height: 140%;
  }
  .isacstar {
    width: 80px;
    height: 80px;
  }
  .starone {
    gap: 40px;
  }
  .Check-out {
    font-size: 30px;
    gap: 20px;
  }
  .Check-out img {
    width: 40px;
  }
  .scrolltext {
    font-size: 20px;
  }
  .herobild {
    max-height: 500px;
    width: 375px;
  }
  .stretchclass {
    padding-bottom: 80px;
  }
  .Work-title {
    font-size: 60px;
    font-weight: 700;
  }
  .Case-title {
    font-size: 30px;
    font-weight: 700;
  }
  .Case-bread {
    width: 70%;
  }
  .isacsmile img {
    max-height: 400px;
  }
  .inside {
    max-width: 70%;
  }
  .hero-button {
    font-size: 30px;
  }
  .hb-bot {
    background-color: #ff5454;
    transform: translate(280px, 430px) rotate(-8deg);
  }
  .hb-bot:hover {
    background-color: #ff5454;
    transform: translate(280px, 430px) rotate(-8deg) scale(105%);
  }
  .jcontentstart {
    padding-top: 100px;
  }
  .workpaddingtop {
    padding-top: 0px;
  }
  .gifbox {
    width: 400px;
    height: 200px;
    background-color: #000;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  .services-data {
    padding-bottom: 80px;
  }
  .services-data-item {
    font-size: 20px;
  }
}
.stretchclass {
  justify-content: center;
  gap: 40px;
  padding-top: 0px;
}

.default-active {
  color: black;
}

.activebuh {
  color: black;
}

